import React, { Component } from "react"
import axios from "axios"

import Layout from "../components/layout"
import SEO from "../components/seo"

const validators = {
  nameValidator: /^[A-Za-z0-9:?!\nÑñ ]{3,}$/,
  textValidator: /^[A-Za-z0-9:?!,\nÑñ ]{3,}$/gim,
  mailValidator: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,4}(?:\.[a-z]{2})?)$/i,
  phoneValidator: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,5}$/im,
}

class Contacto extends Component {
  constructor() {
    super()

    this.state = {
      name: "",
      email: "",
      phone: "",
      motivo: "Consulta",
      comments: "",
    }

    this.formSubmitHandler = this.formSubmitHandler.bind(this)
    this.onInputUpdate = this.onInputUpdate.bind(this)
    this.findCaptchaContainer = this.findCaptchaContainer.bind(this)

    this.captchaFinds = 0
    this.captchaContainer = null
  }

  formSubmitHandler(e) {
    e.preventDefault()

    const { name, phone, email, motivo, comments } = this.state
    const {
      nameValidator,
      textValidator,
      mailValidator,
      phoneValidator,
    } = validators
    const errors = {
      name: null,
      phone: null,
      email: null,
      comments: null,
      captcha: null,
    }

    let validForm = false
    if (!nameValidator.test(name)) {
      errors.name = "Debe ingresar su nombre!"
      validForm = true
    }
    if (!mailValidator.test(email)) {
      errors.mail = "Debe ingresar un correo electrónico válido!"
      validForm = true
    }
    if (!phoneValidator.test(phone)) {
      errors.phone = "Debe ingresar un número telefónico válido!"
      validForm = true
    }
    if (!textValidator.test(comments)) {
      errors.comments = "Debe ingresar sus comentarios!"
      validForm = true
    }

    const formData = new FormData()

    formData.append("name", name)
    formData.append("phone", phone)
    formData.append("email", email)
    formData.append("motivo", motivo)
    formData.append("comments", comments)

    if (validForm) {
      // window.grecaptcha
      //   .execute("6LcancoUAAAAACE_coLdoM1xyCRU9oqLdPops5SA", {
      //     action: "contactForm",
      //   })
      //   .then(token => {
      //     // Add the captcha token to the form data to verify in the serve
      //     formData.append("token", token);
      //     axios({
      //       method: "post",
      //       url: "scripts/contact.php",
      //       data: formData,
      //     }).then(response => {
      //       console.log(response)
      //     })
      //   })
    } else {
    }
  }

  onInputUpdate(e) {
    const target = e.target
    this.setState({ [target.getAttribute("id")]: target.value })
  }

  findCaptchaContainer() {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        this.captchaContainer = document.querySelector(".grecaptcha-badge")
        this.captchaContainer.classList.add("captcha-show")
      })
    } else {
      if (this.captchaFinds === 4) return
      this.captchaFinds++
      setTimeout(() => this.findCaptchaContainer(), 250)
    }
  }

  componentDidMount() {
    setTimeout(() => this.findCaptchaContainer(), 150)
  }

  componentWillUnmount() {
    this.captchaContainer &&
      this.captchaContainer.classList.remove("captcha-show")
  }

  render() {
    return (
      <Layout location="/contacto">
        <SEO title="Contacto" />
        <div className="contacto-wrapper">
          <div className="contacto-mapa">
            <div className="row no-gutters">
              <div className="col-12 col-md-7 d-flex order-2 order-md-1">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    id="mapFrame"
                    title="map-frame"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.996581069431!2d-70.68968058459606!3d-33.44939560484459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c45f35a88cf3%3A0xaf9046df3d47633f!2sSIMET-USACH!5e0!3m2!1ses-419!2scl!4v1576900531440!5m2!1ses-419!2scl"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    width="600"
                    height="450"
                    className="embed-responsive-item"
                  ></iframe>
                </div>
              </div>
              <div className="col-12 col-md-5 direccion order-1 order-md-2">
                <div className="direccion-content">
                  <h3 className="title">SIMET USACH</h3>
                  <p>Universidad de Santiago</p>
                  <p>Facultad de Ingeniería - Depto. Ingeniería Metalúrgica</p>
                  <p>El Belloto 3735, Estación Central</p>
                  <p>Santiago - Chile</p>
                  <p>
                    Fono: <a href="tel:+56-2-2323-4780">+56 2 2323 4780</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="contacto-form">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="h3 text-center">Formulario de Contacto</div>
                  <p className="lead">
                    Por favor complete el siguiente formulario con sus datos y
                    consultas ó comentarios.
                  </p>
                </div>
                <div className="col-12 col-md-9 col-xl-7 form-wrapper">
                  <form onSubmit={this.formSubmitHandler}>
                    <div className="form-group">
                      <label htmlFor="name">Nombre</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nombre Apellido"
                        onChange={this.onInputUpdate}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Correo electrónico"
                        onChange={this.onInputUpdate}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="motivo">Motivo Contacto</label>
                      <select
                        name="motivo"
                        id="motivo"
                        className="form-control"
                        onChange={this.onInputUpdate}
                      >
                        <option value="Consulta">Consulta</option>
                        <option value="Sugerencia">Sugerencia</option>
                        <option value="Reclamo">Reclamo</option>
                        <option value="Verificacion">
                          Solicitar Informe/Certificado
                        </option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Teléfono</label>
                      <input
                        className="form-control"
                        type="tel"
                        name="phone"
                        id="phone"
                        placeholder="Número de Contacto"
                        onChange={this.onInputUpdate}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="comments">Comentarios</label>
                      <textarea
                        name="comments"
                        id="comments"
                        rows="5"
                        className="form-control"
                        onChange={this.onInputUpdate}
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-around mt-4">
                      <button className="btn btn-primary" type="submit">
                        Enviar
                      </button>
                      <button className="btn btn-danger" type="reset">
                        Limpiar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contacto
